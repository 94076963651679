/**
 * Custom variables followed by theme variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */
  
/* Messages */
.tel {
  max-width: 22rem !important
}

.tel .header {
  background: #075E54;
}

.tel .message-sent {
  background: #e1ffc7;
}

.tel .send {
  padding: .4rem .8rem
}

.tel .dialog {
  background: url('https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png');
}

.tel .touch {
  background: conic-gradient(#b3b3b3 0%, #a0a0a0 50%, #b3b3b3 50%, #a0a0a0 100%);
}
