$control-button-size: 5rem;
$control-button-height: 5rem;
$control-button-color: rgba(0, 0, 0, 0.62);
$hover-color: rgba(0, 0, 0, 0.9);

.carousel-control-prev, .carousel-control-next {
  height: $control-button-height;
  width: $control-button-size;
  top: 50%;
  opacity: 1;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;

  &:hover {
    background-color: $hover-color;
  }
}

.carousel-caption, .carousel-control-prev, .carousel-control-next {
  background-color: $control-button-color;
}

.carousel-caption {
  background-color: $control-button-color;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel-indicators {
  margin-bottom: 0;
}
