//
// footer.scss
// Theme component
//

// Brand image

.bg-dark .footer-brand {
  filter: brightness(0) invert(1);
}
