//
// user.scss
// Use this to write your custom SCSS
//

html {
  scroll-behavior: smooth;
}

.navbar-brand-img {
  max-height: 3rem;
}
