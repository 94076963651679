$secondary: #6c757d !default;
$success: #198754 !default;
$info: #0dcaf0 !default;
$warning: #ffc107 !default;
$danger: #dc3545 !default;
$light: #f8f9fa !default;
$dark: #212529 !default;
$secondary-rgb: 108, 117, 125 !default;
$success-rgb: 25, 135, 84 !default;
$info-rgb: 13, 202, 240 !default;
$warning-rgb: 255, 193, 7 !default;
$danger-rgb: 220, 53, 69 !default;
$light-rgb: 248, 249, 250 !default;
$dark-rgb: 33, 37, 41 !default;
$body-color: #212529 !default;
$body-color-rgb: 33, 37, 41 !default;
$body-bg-rgb: 255, 255, 255 !default;
$body-bg: #fff !default;
$border-color: #dee2e6 !default;
$border-color-translucent: rgba(0, 0, 0, 0.175) !default;
$link-color: #0d6efd !default;
$link-hover-color: #0a58ca !default;
$highlight-bg: #fff3cd;

