//
// bigpicture.scss
// Plugin overrides
//

#bp_container {
  background: rgba($white, 0.873) !important;
}

#bp_container > * {
  box-shadow: none !important;
}

.bp-x {
  display: none;
}
