//
// close.scss
// Extended from Bootstrap
//

.btn-close {
  &:hover,
  &:focus {
    outline: none;
  }
}
