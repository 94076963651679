//
// sizing.scss
// Extended from Bootstrap
//

// Cover
//
// Spans the container till the edge of the viewport

.w-cover {
  @each $width, $value in $container-max-widths {
    @include media-breakpoint-up(#{$width}) {
      width: calc(100% + (100vw - #{$value}) * 0.5 + #{$grid-gutter-width});
    }
  }
}
